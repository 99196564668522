.shortLink {
	display: grid;
	gap: 20px;
}

.shortLink__form {
	display: grid;
	gap: 10px;
	margin-top: 12px;
}

.shortLink__fieldset {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	margin: 0;
	padding: 0;
	border: none;
}

.shortLink__fieldset--triple {
	grid-template-columns: 1fr 1fr 1fr;
}

.shortLink__input {
	outline: none;
	padding: 8px 10px;
	border: 1px solid #f2f2f2;
}

.shortLink__input::placeholder {
	opacity: 1;
	color: #434a54;
}

.shortLink__btn {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	height: 32px;
	outline: none;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
	white-space: pre;
	color: rgb(73, 76, 97);
	cursor: pointer;
	padding: 0px 12px;
	border-radius: 2px;
	border: 1px solid rgb(224, 225, 226);
	background-color: rgb(251, 251, 251);
}

.shortLink__table {
	border-collapse: collapse;
}

.shortLink__tr {
	border: 1px solid #f2f2f2;
}

.shortLink__tr:nth-child(2n + 2) {
	background-color: #f7f7f7;
}

.shortLink__td {
	padding: 8px;
	font-size: 12px;
	line-height: 1.4;
	color: #434a54;
	white-space: nowrap;
}

.shortLink__tr > .shortLink__td:first-child {
	font-weight: 500;
	width: fit-content;
	white-space: nowrap;
	border-right: 1px solid #f2f2f2;
}

.shortLink__tr > .shortLink__td:last-child {
	width: 100%;
	max-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.shortLink__td > a {
	color: inherit;
}

.shortLink__copy {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
	width: 100%;
	padding: 0;
	cursor: pointer;
	border-style: none;
	background-color: transparent;
}

.shortLink__copy::before {
	content: attr(data-title);
	position: absolute;
	top: 12px;
	left: 50%;
	transform: translateX(-50%);
	width: max-content;

	font-size: 10px;
	line-height: 10px;
	color: #ffffff;
	opacity: 0;
	padding: 2px 5px;
	border-radius: 8px;
	background-color: #000000;
	transition: all 0.5s ease;
}

.shortLink__copy.shortLink__copy--active::before {
	opacity: 1;
	top: -10px;
}
