.statistics__title {
	margin: 2px 0 20px 0;
}

.statistics__wrapper {
	display: flex;
	gap: 40px;
	margin-bottom: 40px;
}

.statistics__chart {
	flex: 1;
}

.statistics__total {
	margin: 0 0 5px 0;
}

.statistics__row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
}

.statistics__row--span {
	font-weight: 600;
}

.statistics__data {
	min-width: 180px;
	display: flex;
	flex-direction: column;
}

.statistics__subtitle {
	font-size: 16px;
	line-height: 16px;
	font-weight: 400;
	margin: 0 0 10px 0;
}

.statistics__subtitle--span {
	font-weight: 600;
}

.statistics__hr {
	margin-bottom: 10px;
	border: 0.5px solid #000000;
}

.statistics__list {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 5px;
	height: 100%;
	margin-bottom: 5px;
	max-height: 200px;
	overflow-y: auto;
}

.statistics__list > .statistics__subtitle {
	margin: 0 0 5px 0;
}

.statistics__label {
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 16px;
	line-height: 16px;
}

.statistics__btn {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	height: 32px;
	outline: none;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
	white-space: pre;
	color: rgb(73, 76, 97);
	cursor: pointer;
	padding: 0px 12px;
	border-radius: 2px;
	border: 1px solid rgb(224, 225, 226);
	background-color: rgb(251, 251, 251);
}
