@import "fonts.css";

@import "./orders.css";
@import "./blogers.css";
@import "./shortLink.css";
@import "./statistics.css";

.input {
	all: unset;
	width: auto;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 2px;
	padding: 8px;
	font-size: 14px;
	line-height: 1;
	border: 1px solid #e0e1e2;
	background-color: rgb(251, 251, 251);
	height: 32px;
	outline: none;
	box-sizing: border-box;
}

.notion-page-offset {
	margin-top: 0;
}

.notion-title {
	margin-top: 0;
}

.codex-editor__redactor {
	padding-bottom: 80px !important;
}

@media (min-width: 651px) {
	.codex-editor--narrow .ce-toolbar__actions {
		left: -80px !important;
		border: 1px solid #e8e8eb;
		background-color: #fff;
		-webkit-box-shadow: 0 3px 15px -3px rgb(13 20 33 / 13%);
		box-shadow: 0 3px 15px -3px rgb(13 20 33 / 13%);
		border-radius: 6px;
		right: auto !important;
	}

	.codex-editor--narrow .codex-editor__redactor {
		margin-right: 0;
	}

	.ce-toolbar__settings-btn,
	.ce-toolbar__plus {
		width: 26px !important;
		border-radius: 5px;
	}

	.codex-editor--narrow .ce-toolbar__actions:last-child {
		padding-right: 0;
	}
}

.ce-inline-tool--link {
	display: none;
}
