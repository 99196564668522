.blogers {
	display: grid;
	gap: 16px;
	margin-top: 20px;
}

.blogers__statistics {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
}

.blogers__earned {
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
}

.blogers__earned--span {
	font-weight: 600;
}

.blogers__line {
	width: 2px;
	height: 15px;
	background-color: silver;
}

.bloger {
	padding: 10px 12px;
	border-radius: 8px;
	border: 1px solid #e9edf3;
	background-color: #ffffff;
}

.bloger__wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 5px;
}

.bloger__picture {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: silver;
}

.bloger__img {
	border-style: none;
	border-radius: 50%;
}

.bloger__name {
	font-weight: bold;
	word-break: break-all;
}

.bloger__table {
	border-collapse: collapse;
}

.bloger__tr {
	border: 1px solid #f2f2f2;
}

.bloger__tr:nth-child(2n + 2) {
	background-color: #f7f7f7;
}

.bloger__td,
.bloger__th {
	padding: 8px;
	font-size: 14px;
	line-height: 1.4;
	color: #434a54;
	text-align: left;
	border-right: 1px solid #f2f2f2;
}

.bloger__tr > .bloger__th:last-child {
	border-right: none;
}

.bloger__tr > .bloger__td:last-child {
	border-right: none;
}
