.orders {
	display: grid;
	gap: 16px;
	margin-top: 20px;
}

.orders__statistics {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

.orders__wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
}

.orders__filters {
	display: flex;
	align-items: center;
	gap: 8px;
}

.orders__status {
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	padding: 3px 10px;
	border-radius: 10px;
	text-align: center;
	text-transform: capitalize;
}

.orders__status--span {
	font-weight: 600;
}

.orders__status--draft {
	background-color: rgb(255, 255, 170);
}
.orders__status--cancelled {
	background-color: rgb(255, 217, 170);
}
.orders__status--error {
	background-color: rgb(255, 170, 170);
}
.orders__status--completed {
	background-color: rgb(187, 255, 170);
}

.order__label {
	display: inline-flex;
	flex-direction: column;
	gap: 5px;
}

.order__select {
	border: 1px solid rgb(224, 225, 226);
	gap: 4px;
	cursor: pointer;
	display: flex;
	padding: 0px 12px;
	align-items: center;
	line-height: 15px;
	white-space: pre;
	border-radius: 2px;
	justify-content: center;
	background-color: rgb(251, 251, 251);
	height: 32px;
	font-size: 12px;
	font-weight: 600;
	color: rgb(73, 76, 97);
	flex-direction: row;
}

.orders__earned {
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
}

.orders__earned--span {
	font-weight: 600;
}

.orders__line {
	width: 2px;
	height: 15px;
	background-color: silver;
}

.orders__form {
	display: grid;
	gap: 10px;
}

.order {
	padding: 10px 12px;
	border-radius: 8px;
	border: 1px solid #e9edf3;
	background-color: #ffffff;
}

.order__header {
	padding: 8px 0;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e9edf3;
}

.order__id,
.order__info {
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
}

.order__status {
	width: 15px;
	height: 15px;
	margin: 0 auto;
	border-radius: 50%;
}

.order__status--draft {
	background-color: rgb(244, 244, 43);
}
.order__status--cancelled {
	background-color: rgb(244, 154, 43);
}
.order__status--error {
	background-color: rgb(242, 96, 96);
}
.order__status--completed {
	background-color: rgb(123, 237, 94);
}

.order__content {
	display: grid;
	gap: 10px;
}

.order__info {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 4px;
}

.order__info--span {
	font-weight: 600;
}

.order__hr {
	border: 0.5px solid #e9edf3;
}

.order__table {
	border-collapse: collapse;
}

.order__tr {
	border: 1px solid #f2f2f2;
}

.order__tr:nth-child(2n + 2) {
	background-color: #f7f7f7;
}

.order__td,
.order__th {
	padding: 8px;
	font-size: 14px;
	line-height: 1.4;
	color: #434a54;
	text-align: left;
	border-right: 1px solid #f2f2f2;
}

.order__tr > .order__th:last-child {
	border-right: none;
}

.order__tr > .order__td:last-child {
	border-right: none;
}
